<template>
  <div class="common-modal">
    <div class="common-modal-mask"></div>
    <div class="common-modal-box gradient-border" :style="styleOption">
      <slot></slot>
      <span class="common-modal-close" @click="closeModal"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    styleOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>
<style lang="less" scoped>
.common-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  .common-modal-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.5;
  }
  .common-modal-box {
    position: absolute;
    left: 50%;
    top: 50%;
    background: #faf2e5;
    color: #ad4d15;
  }
  .moda-tip-txt {
    font-weight: bold;
  }
  .common-modal-close {
    position: absolute;
    width: 27px;
    height: 27px;
    right: 15px;
    top: 15px;
    background: url("../assets/images/close_login.png") no-repeat;
  }
}
</style>
