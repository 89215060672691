import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import "ant-design-vue/dist/antd.css";
import store from "./store";
import bootstrap from "@/bootstrap";
import Antd from "ant-design-vue";
// document.domain = "zol.com.cn";
Vue.use(Antd);
bootstrap({ router, store, message: Vue.prototype.$message });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
