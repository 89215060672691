// 401拦截
// const resp401 = {
//   /**
//    * 响应数据之前做点什么
//    * @param response 响应对象
//    * @param options 应用配置 包含: {router, i18n, store, message}
//    * @returns {*}
//    */
//   onFulfilled(response, options) {
//     const { message } = options;
//     if (response.code === 401) {
//       message.error("无此权限");
//     }
//     return response;
//   },
//   /**
//    * 响应出错时执行
//    * @param error 错误对象
//    * @param options 应用配置 包含: {router, i18n, store, message}
//    * @returns {Promise<never>}
//    */
//   onRejected(error, options) {
//     const { message } = options;
//     const { response } = error;
//     if (response.status === 401) {
//       message.error("无此权限");
//     }
//     return Promise.reject(error);
//   },
// };

// const resp403 = {
//   onFulfilled(response, options) {
//     const { message } = options;
//     if (response.code === 403) {
//       message.error("请求被拒绝");
//     }
//     return response;
//   },
//   onRejected(error, options) {
//     const { message } = options;
//     const { response } = error;
//     if (response.status === 403) {
//       message.error("请求被拒绝");
//     }
//     return Promise.reject(error);
//   },
// };

const respCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  // onFulfilled(response, { store }) {
  onFulfilled(response) {
    return new Promise((resolve, reject) => {
      let res = response.data;
      if (response?.status === 200) {
        resolve(res);
        // if (!res.errcode) {
        //   if (/adminlogout/.test(response.config?.url)) {
        //     store.commit("account/setUser");
        //     localStorage.removeItem(process.env.VUE_APP_USER_KEY);
        //   }
        //   resolve(res);
        // } else {
        //   // reject(res);
        //   console.log(
        //     `API: %c ${response.config.url} %cerror! message: %c${res.errmsg}`,
        //     "color: #f60",
        //     "",
        //     "color: #f60"
        //   );
        // }
      } else {
        reject(res);
      }
    });
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    message.error(error.message);
    return Promise.reject(error);
  },
};

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config) {
    return config;
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options;
    message.error(error.message);
    return Promise.reject(error);
  },
};

export default {
  request: [reqCommon], // 请求拦截
  response: [respCommon], // 响应拦截
};
