// import { hasAuthority } from "@/utils/authority-utils";
// import { loginIgnore } from "@/router/index";
// import { getUserInfo } from "@/services/user";
import NProgress from "nprogress";

NProgress.configure({ showSpinner: false });

/**
 * 进度条开始
 * @param to
 * @param form
 * @param next
 */
const progressStart = (to, from, next) => {
  // start progress bar
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  next();
};

/**
 * 登录守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
// const loginGuard = (to, _form, next, options) => {
// const { store } = options;
// if (!loginIgnore.includes(to) && !store.getters["account/isLogin"]) {
//   getUserInfo()
//     .then((res) => {
//       if (res.userId) {
//         store.commit("account/setUser", res);
//         next();
//       } else {
//         next({ path: "/login" });
//       }
//     })
//     .catch(() => {
//       next({ path: "/login" });
//     });
// } else {
// next();
// }
// };

/**
 * 混合导航模式下一级菜单跳转重定向
 * @param to
 * @param from
 * @param next
 * @param options
 * @returns {*}
 */
// const redirectGuard = (to, from, next, options) => {
//   const { store } = options;
//   const getFirstChild = (routes) => {
//     const route = routes[0];
//     if (!route.children || route.children.length === 0) {
//       return route;
//     }
//     return getFirstChild(route.children);
//   };
//   if (store.state.setting.layout === "mix") {
//     const firstMenu = store.getters["setting/firstMenu"];
//     if (firstMenu.find((item) => item.fullPath === to.fullPath)) {
//       store.commit("setting/setActivatedFirst", to.fullPath);
//       const subMenu = store.getters["setting/subMenu"];
//       if (subMenu.length > 0) {
//         const redirect = getFirstChild(subMenu);
//         return next({ path: redirect.fullPath });
//       }
//     }
//   }
//   next();
// };

/**
 * 进度条结束
 * @param to
 * @param form
 * @param options
 */
const progressDone = () => {
  // finish progress bar
  NProgress.done();
};

export default {
  beforeEach: [progressStart],
  afterEach: [progressDone],
};
