var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showLoginMoadl)?_c('div',{attrs:{"id":"login-page"}},[_c('div',{staticClass:"login-mask"}),_c('div',{staticClass:"login-box gradient-border"},[(!_vm.isToverifyEmail)?_c('div',{staticClass:"message-login"},[_c('div',{staticClass:"login-title f16"},[_vm._v("短信登录")]),_c('div',{staticClass:"phone-box f14 clearfix"},[_c('span',{staticClass:"phone-icon fl"}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phoneNum),expression:"phoneNum",modifiers:{"trim":true}}],staticClass:"fl",attrs:{"type":"number","placeholder":"请输入手机号"},domProps:{"value":(_vm.phoneNum)},on:{"keyup":function($event){return _vm.limitLength(_vm.phoneNum, 11, 'phoneNum')},"input":function($event){if($event.target.composing)return;_vm.phoneNum=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('p',{staticClass:"phone-tip tip-box",class:{
          error: _vm.phoneNumTip.length > 0,
        }},[_vm._v(" "+_vm._s(_vm.phoneNumTip)+" ")]),_c('div',{staticClass:"verify-code-box clearfix"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.verifyCode),expression:"verifyCode",modifiers:{"trim":true}}],staticClass:"verify-code-ipt mr fl",attrs:{"type":"text","placeholder":"请输入验证码"},domProps:{"value":(_vm.verifyCode)},on:{"keyup":function($event){return _vm.limitLength(_vm.verifyCode, 4, 'verifyCode')},"input":function($event){if($event.target.composing)return;_vm.verifyCode=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('img',{staticClass:"fl",attrs:{"src":_vm.graphicVerificationCode,"id":"codeImg","width":"110","height":"46"}}),_c('span',{staticClass:"fl pointer f14 clearfix",attrs:{"id":"refresh"},on:{"click":_vm.getGraphicVerificationCode}},[_c('em',{staticClass:"changeIcon fl"}),_c('i',{staticClass:"changeTxt fl"},[_vm._v("换一换")])])]),_c('p',{staticClass:"verify-code-tip tip-box",class:{ error: _vm.verifyCodeTip.length > 0 }},[_vm._v(" "+_vm._s(_vm.verifyCodeTip)+" ")]),_c('div',{staticClass:"password-box clearfix"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],staticClass:"password-ipt mr fl",attrs:{"type":"number","placeholder":"请输入动态密码"},domProps:{"value":(_vm.password)},on:{"keyup":function($event){return _vm.limitLength(_vm.password, 6, 'password')},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"getPasswordBtn pointer f12 fl",on:{"click":_vm.getPassword}},[_vm._v(_vm._s(+_vm.countDown <= 120 && +_vm.countDown !== 0 ? `动态密码已发送(${_vm.countDown}s)` : "获取动态密码"))])]),_c('p',{staticClass:"password-tip tip-box",class:{ error: _vm.passwordTip.length > 0 }},[_vm._v(" "+_vm._s(_vm.passwordTip)+" ")]),_c('div',{class:[
          'nextStepBtn',
          'f16',
          'mb',
          'pointer',
          _vm.hasAgree ? 'common-btn-halftone' : 'common-btn-light',
        ],on:{"click":_vm.nextStepFn}},[_vm._v(" 下一步 ")]),_c('div',{staticClass:"agreement-tip f14"},[_c('span',{staticClass:"state-txt pointer",class:{ act: _vm.hasAgree },on:{"click":_vm.agreeHandler}},[_vm._v("我已阅读并同意"),_c('router-link',{staticClass:"agreement",attrs:{"target":"_blank","to":"/agreement"}},[_vm._v("《【金翎奖】隐私政策》")]),_vm._v("协议")],1)])]):_c('div',{staticClass:"email-verify"},[_c('div',{staticClass:"login-title f16 email-title"},[_vm._v("邮箱验证")]),_c('div',{staticClass:"email-box clearfix"},[_c('span',{staticClass:"email-icon fl"}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"fl",attrs:{"type":"text","placeholder":"请输入接收中奖信息的邮箱地址"},domProps:{"value":(_vm.email)},on:{"keyup":function($event){return _vm.limitLength(_vm.email, 30, 'email')},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('p',{staticClass:"email-tip tip-box"},[_vm._v(" 注： 邮箱是为了能及时通知您中奖相关信息，请认真填写。 ")]),_c('p',{staticClass:"email-tip tip-box"},[_vm._v(_vm._s(_vm.emailTip))]),_c('div',{class:[
          'sureBtn',
          'pointer',
          _vm.email.length > 0 ? 'common-btn-halftone' : 'common-btn-light',
        ],on:{"click":_vm.verifyEmail}},[_vm._v(" 确定 ")])]),_c('span',{staticClass:"close-login",on:{"click":_vm.closeLogin}})]),_vm._m(0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agreement-box"},[_c('h4',[_vm._v("《金翎奖用户协议》")]),_c('div',{staticClass:"agreement-cont"})])
}]

export { render, staticRenderFns }