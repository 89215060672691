export default {
  namespaced: true,
  state: {
    userInfo: undefined,
    showLoginMoadl: false,
    cateObj: {
      id: 0,
      index: 0,
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    setUserInfo(state, user) {
      state.userInfo = user;
    },
    removeUserInfo(state) {
      state.userInfo = [];
    },
    setShowLoginMoadl(state, showLoginMoadl) {
      state.showLoginMoadl = showLoginMoadl;
    },
    setNavObj(state, cateObj) {
      state.cateObj = cateObj;
    },
  },
};
