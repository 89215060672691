import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import PageLayout from "@/layouts/PageLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "登录页",
    component: () => import("@/views/login"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/exception/404"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/exception/403"),
  },
  {
    path: "/",
    name: "home",
    component: PageLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
    ],
  },
  {
    path: "/lottery/:id",
    name: "lottery",
    component: PageLayout,
    redirect: "/lottery",
    children: [
      {
        path: "/lottery/:id",
        name: "lottery",
        component: () =>
          import(/* webpackChunkName: "lottery" */ "../views/LotteryView.vue"),
      },
    ],
  },
  {
    path: "/article/:id",
    name: "article",
    component: PageLayout,
    redirect: "/article",
    children: [
      {
        path: "/article/:id",
        name: "article",
        component: () =>
          import(/* webpackChunkName: "article" */ "../views/ArticleView.vue"),
      },
    ],
  },
  {
    path: "/game/:id",
    name: "game",
    component: PageLayout,
    redirect: "/game",
    children: [
      {
        path: "/game/:id",
        name: "game",
        component: () =>
          import(/* webpackChunkName: "game" */ "../views/GameDetailView.vue"),
      },
    ],
  },
  {
    path: "/agreement",
    name: "agreement",
    component: PageLayout,
    redirect: "/agreement",
    children: [
      {
        path: "/agreement",
        name: "agreement",
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "../views/AgreementView.vue"
          ),
      },
    ],
  },
  {
    path: "/personal",
    name: "personal",
    component: PageLayout,
    redirect: "/personal",
    children: [
      {
        path: "/personal",
        name: "personal",
        component: () => import("../views/PersonalView.vue"),
        redirect: "/personal/material",
        children: [
          {
            path: "material",
            name: "material",
            component: () => import("../views/personal/MaterialView.vue"),
          },
          {
            path: "voteRecord",
            name: "voteRecord",
            component: () => import("../views/personal/VoteRecordView.vue"),
          },
          {
            path: "lotteryResult",
            name: "lotteryResult",
            component: () => import("../views/personal/LotteryResultView.vue"),
          },
        ],
      },
    ],
  },
];
// 不需要登录拦截的路由配置
const loginIgnore = {
  names: ["404", "403"], //根据路由名称匹配
  paths: [
    // "/login",
    // "/home",
    // "/lottery",
    // "/article",
    // "/personal",
    // "/personal/material",
    // "/personal/voteRecord",
    // "/personal/lotteryResult",
    // "/game/9",
  ], //根据路由fullPath匹配
  /**
   * 判断路由是否包含在该配置中
   * @param route vue-router 的 route 对象
   * @returns {boolean}
   */
  includes(route) {
    return this.names.includes(route.name) || this.paths.includes(route.path);
  },
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export { loginIgnore, router };
