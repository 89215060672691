import { request } from "@/utils/request";

// 投票
export const gameVote = (data) => {
  return request({
    url: `/do.game.vote`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// 游戏详情页
export const fetchGameDetail = (data) => {
  return request({
    url: `/game.detail`,
    method: "get",
    data,
  });
};

// 投票记录列表
export const fetchVoteList = (data) => {
  return request({
    url: `/get.vote.list`,
    method: "get",
    data,
  });
};
