var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"wrap clearfix"},[_c('router-link',{staticClass:"logo fl",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/images/logo.png"),"width":"31","height":"51","alt":""}})]),_c('div',{staticClass:"nav-box"},[[_c('a-dropdown',[_c('router-link',{staticClass:"ant-dropdown-link header-home",class:{ active: _vm.showNav },attrs:{"to":"/"}},[_vm._v("首页")])],1)],(_vm.showNav)?_vm._l((_vm.navList),function(item,index){return _c('a-dropdown',{key:item.cateId,attrs:{"getPopupContainer":(triggerNode) => {
                return triggerNode.parentNode || _vm.document.body
              }}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":(e) => e.preventDefault()}},[_vm._v(" "+_vm._s(item.cateName)+" ")]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((item.cateList),function(subItem){return _c('a-menu-item',{key:subItem.subCateId,on:{"click":function($event){return _vm.goAnchor(`${item.cateId}`, index, subItem.subCateId)}}},[_vm._v(_vm._s(subItem.subCateName))])}),1)],1)}):_vm._e()],2),(_vm.userInfo && !_vm.userInfo.uid)?_c('span',{staticClass:"fr login",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"https://icon.zol-img.com.cn/2022active/1109jinling/images/head.png","width":"35","height":"35","alt":""}}),_c('span',{staticClass:"login-txt",on:{"click":_vm.login}},[_vm._v("登录")])]):_c('a-dropdown',{staticClass:"fr login",attrs:{"getPopupContainer":(triggerNode) => {
            return triggerNode.parentNode || _vm.document.body
          }}},[_c('router-link',{staticClass:"ant-dropdown-link",attrs:{"target":"_blank","to":{ name: 'material' }}},[_c('div',{staticClass:"header-avatar fl"},[_c('img',{attrs:{"src":_vm.loginPhoto && _vm.loginPhoto.length > 0
                  ? _vm.loginPhoto
                  : 'https://icon.zol-img.com.cn/2022active/1109jinling/images/head.png',"width":"35","height":"35","alt":""}})])]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.exit},slot:"overlay"},[_c('a-menu-item',{key:"1",staticClass:"exit"},[_vm._v("退出")])],1)],1),_c('LoginView')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }