<template>
  <div class="home">
    <div v-if="!isEnd" class="home-normal">
      <div class="main-kv"></div>
      <div class="wrap banner loop" v-if="bannerData.length">
        <swiper
          v-if="bannerData.length > 0"
          class="slideBox swiper-no-swiping swiper-wrapper"
          :options="loopSwiperOption"
          ref="mySwiper"
        >
          <swiper-slide v-for="(item, index) in bannerData" :key="index">
            <a target="_blank" :href="item.url">
              <img :src="item.img" :alt="item.title" />
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="wrap news clearfix" v-if="focusData.length">
        <div class="news-l fl">
          <div class="news-loop">
            <swiper
              class="slideBox swiper-no-swiping swiper-wrapper"
              :options="newsSwiperOption"
              ref="newsSwiper"
            >
              <swiper-slide v-for="(item, index) in focusData" :key="index">
                <a target="_blank" :href="item.url">
                  <img
                    :src="item.img"
                    :alt="item.title"
                    width="700"
                    height="246"
                  />
                  <p class="single-row-ellipsis">
                    {{ item.title }}
                  </p>
                </a>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <ul class="news-r fr">
          <li v-for="item in newsList" :key="item.articleId">
            {{ item.url[0] }}
            <a target="_blank" :href="item.url[1]">
              <span class="news-date">{{ item.date }}</span>
              <span class="news-title single-row-ellipsis">{{
                item.title
              }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="wrap classify">
        <div v-for="(item, index) in gameData" :key="index">
          <div
            class="classify-item"
            v-if="item.cateList && item.cateList.length > 0"
            :id="`anchor${item.cateId}`"
          >
            <div class="t1 common-title">
              <span class="title-txt">{{ item.cateName }}</span>
            </div>
            <div class="classify-box">
              <div class="classify-box-in clearfix">
                <div class="classify-box-center clearfix">
                  <div class="classify-tab fl">
                    <div
                      class="classify-tab-item"
                      v-for="(subItem, tabIndex) in item.cateList"
                      :key="tabIndex"
                      :class="{
                        cur:
                          subCateList[index] &&
                          subCateList[index].some((curItem, curIndex) => {
                            if (curIndex === 0) {
                              return (
                                curItem &&
                                curItem.subcateId == subItem.subCateId
                              )
                            }
                          }),
                      }"
                    >
                      <span
                        class="multi-row-ellipsis"
                        @click="subCateClick(subItem.subCateId, index)"
                        >{{ subItem.subCateName }}</span
                      >
                    </div>
                  </div>
                  <div class="classify-cont fr">
                    <ul
                      class="clearfix"
                      :class="{ showAll: +checkMoreIndex === +index }"
                    >
                      <li
                        v-for="(detailItem, detailIndex) in subCateList[index]"
                        :key="detailIndex"
                      >
                        <router-link :to="`/game/${detailItem.gameId}`">
                          <img
                            :src="detailItem.gameImg"
                            :alt="detailItem.gameName"
                            width="205"
                            height="150"
                          />

                          <span class="game-name single-row-ellipsis">{{
                            detailItem.gameName
                          }}</span>
                        </router-link>
                        <div class="game-vote clearfix">
                          <span class="fl">
                            当前票数：
                            <span class="vote-num">{{
                              detailItem.voteNum
                            }}</span>
                          </span>
                          <span
                            class="vote-btn fr pointer"
                            @click="gameVote(detailItem.gameId, detailItem)"
                            >投票</span
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                  <span
                    class="more pointer f14"
                    v-if="
                      subCateList[index] &&
                      subCateList[index].length > 8 &&
                      +checkMoreIndex !== +index
                    "
                    @click="checkMoreIndex = index"
                    >查看更多</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap award clearfix" v-if="prizeData.length">
        <div class="common-title">
          <span class="award-title title-txt">奖品专区</span>
        </div>
        <div class="award-loop">
          <swiper
            class="slideBox swiper-no-swiping swiper-wrapper"
            :options="awardSwiperOption"
            ref="awardSwiper"
          >
            <swiper-slide v-for="(item, index) in prizeData" :key="index">
              <span>
                <img
                  :src="item.img"
                  :alt="item.title"
                  width="210"
                  height="157"
                />
                <p class="single-row-ellipsis">{{ item.title }}</p>
              </span>
            </swiper-slide>
          </swiper>
          <div class="award-next swiper-button-next" slot="button-next"></div>
          <div class="award-prev swiper-button-prev" slot="button-prev"></div>
        </div>
      </div>
      <div class="wrap review clearfix">
        <div class="t2 common-title">
          <span class="title-txt">往届回顾</span>
        </div>
        <div
          :class="{ 'review-cont-click': isShowReviewLoop }"
          class="review-cont clearfix"
        >
          <div class="review-l fl">
            <img
              :src="reviewPicArr[0].img"
              width="596"
              height="447"
              alt=""
              @click="showReviewLoop(0)"
            />
            <img
              :src="reviewPicArr[1].img"
              width="294"
              height="220"
              alt=""
              @click="showReviewLoop(1)"
            />
          </div>
          <div class="review-r fr">
            <img
              :src="reviewPicArr[2].img"
              width="294"
              height="220"
              alt=""
              @click="showReviewLoop(2)"
            />
            <img
              :src="reviewPicArr[3].img"
              width="596"
              height="447"
              alt=""
              @click="showReviewLoop(3)"
            />
          </div>
        </div>
        <div class="review-loop" v-if="isShowReviewLoop">
          <swiper
            class="slideBox swiper-no-swiping swiper-wrapper"
            :options="reviewSwiperOption"
            ref="reviewSwiper"
          >
            <swiper-slide v-for="(item, index) in reviewPicArr" :key="index">
              <a href="javascript:;">
                <img :src="item.img" alt="" width="1000" height="750" />
              </a>
            </swiper-slide>
          </swiper>
          <div class="review-next swiper-button-next" slot="button-next"></div>
          <div class="review-prev swiper-button-prev" slot="button-prev"></div>
          <span
            class="close-review-loop pointer"
            @click="isShowReviewLoop = false"
          >
          </span>
        </div>
      </div>
      <div class="wrap partners logo clearfix">
        <div class="t3 common-title">
          <span class="title-txt">合作媒体</span>
        </div>
        <div class="partners-cont">
          <swiper
            class="slideBox swiper-no-swiping swiper-wrapper"
            :options="partnersSwiperOption"
            ref="mySwiper"
          >
            <swiper-slide v-for="(item, index) in partnersArr" :key="index">
              <!-- <a :href="item.url"> -->
              <img
                :src="subItem"
                v-for="(subItem, subIndex) in item"
                :key="subIndex"
              />
              <!-- </a> -->
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <div
        class="back-top pointer"
        v-show="isShowBackTop"
        ref="backTopBtn"
        @click="backTop"
      ></div>
      <common-modal
        class="draw-chance-modal"
        :style-option="lotteryOption"
        v-if="lotteryModalShow"
        @closeModal="(result) => (lotteryModalShow = result)"
      >
        <div class="draw-chance-tip">
          <div class="draw-chance-tip-in clearfix">
            目前您有<span class="draw-chance-num font-bold">{{
              drawChanceNum
            }}</span
            >次抽奖机会
          </div>
        </div>
        <div class="draw-chance-cont">
          <div
            class="draw-classify-item clearfix align-left"
            v-for="item in drawChanceList"
            :key="item.subId"
          >
            <div class="classify-title f18 font-bold single-row-ellipsis">
              {{ item.name }}
            </div>
            <ul class="game-list">
              <li
                v-for="(subItem, subIndex) in item.list"
                :key="subItem.gameId"
                :class="{ 'no-border': subIndex === item.list.length - 1 }"
              >
                <span class="draw-game-name multi-row-ellipsis f16 fl">{{
                  subItem.gameName
                }}</span>
                <span class="game-chance fl align-center">
                  <p class="f18 cur-chance-num font-bold line-h1">
                    {{ subItem.chanceNum }}次
                  </p>
                  <p class="game-chance-txt line-h1">抽奖机会</p>
                </span>
                <router-link
                  target="_blank"
                  class="common-btn-halftone toLottery f14 fr"
                  :to="`/lottery/${subItem.gameId}`"
                  >去抽奖
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </common-modal>
    </div>
    <div v-else>
      <a target="_self" :href="endPic.url">
        <img class="bigImg" :src="endPic.img" alt="" />
      </a>
    </div>
    <!-- 投票成功 -->
    <common-modal
      :style-option="successOption"
      v-if="successModalShow"
      @closeModal="(result) => (successModalShow = result)"
    >
      <img
        src="https://icon.zol-img.com.cn/2022active/1109jinling/images/icon_success.png"
        width="122"
        height="54"
        alt=""
        v-if="+voteType === 1"
      />
      <div class="f22 congratulations line-h1 font-bold" v-if="+voteType === 1">
        恭喜您投票成功
      </div>
      <div class="f18 congratulations line-h1 font-bold" v-if="+voteType !== 1">
        {{
          +voteType === 2
            ? '今天投票已达上限，请明天再来'
            : '不在投票时间段内，暂不可投票'
        }}
      </div>
      <div class="f16 line-h1" v-if="+drawChanceNum > 0">
        您有<span class="color2 font-bold">{{ drawChanceNum }}</span
        >次抽奖机会
      </div>
      <div class="f16 line-h1" v-if="+drawChanceNum === 0 && +voteType === 2">
        目前已无抽奖机会
      </div>
      <div class="f16" v-if="+voteType === 3">
        投票时间：<br />
        {{ voteTime }}
      </div>
      <div
        class="common-btn-halftone toDraw f18 pointer"
        v-if="+drawChanceNum > 0"
        @click="goLottery"
      >
        去抽奖
      </div>
    </common-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {
  fetchEndPic,
  fetchPcData,
  fetchGameData,
  subCateData,
  fetchDrawChanceList,
} from '@/services/home.js'
import { gameVote } from '@/services/vote.js'
import CommonModal from '@/components/CommonModal'
import {
  partnersArr,
  loopSwiperOption,
  newsSwiperOption,
  awardSwiperOption,
  reviewSwiperOption,
  partnersSwiperOption,
  reviewPicArr,
} from '@/assets/data/optionData.js'

export default {
  name: 'HomeView',
  components: {
    swiper,
    swiperSlide,
    CommonModal,
  },
  computed: {
    ...mapState('account', ['showLoginMoadl', 'cateObj', 'userInfo']),
  },
  watch: {
    cateObj: {
      handler(newVal) {
        this.subCateClick(+newVal.id, newVal.index)
      },
      deep: true,
    },
  },
  data: function () {
    return {
      isShowReviewLoop: false,
      isShowBackTop: false,
      isEnd: false,
      endPic: {},
      focusData: [],
      bannerData: [],
      prizeData: [],
      gameData: [],
      newsList: [],
      partnersArr: partnersArr,
      reviewPicArr: reviewPicArr,
      // 轮播图相关数据
      loopSwiperOption: loopSwiperOption,
      newsSwiperOption: newsSwiperOption,
      awardSwiperOption: awardSwiperOption,
      reviewSwiperOption: reviewSwiperOption,
      partnersSwiperOption: partnersSwiperOption,
      subCateList: [],
      checkMoreIndex: -1,
      lotteryOption: {
        width: 516 + 'px',
        height: 'auto',
        'margin-left': -258 + 'px',
        'margin-top': -258 + 'px',
        padding: `92px 0 0`,
      },
      lotteryModalShow: false,
      drawChanceList: [],
      successOption: {
        //投票成功弹窗样式配置
        width: 386 + 'px',
        height: 'auto',
        'margin-left': -193 + 'px',
        'margin-top': -147 + 'px',
        padding: `38px 0`,
      },
      successModalShow: false,
      voteType: 0,
      drawChanceNum: 0,
      voteTime: '',
      gameId: 0,
      refresh: '',
    }
  },
  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = 'https://jlj.chinajoy.net/m/'
    }
    this.refresh = this.$route.query.refresh
  },
  mounted() {
    var _that = this
    window.addEventListener('scroll', function () {
      var t = document.documentElement.scrollTop
      if (t > 1200) {
        _that.isShowBackTop = true
      } else {
        _that.isShowBackTop = false
      }
    })
    this.fetchEndPic()
  },
  methods: {
    fetchEndPic() {
      fetchEndPic({ refresh: this.refresh }).then((res) => {
        if (+res.errcode === 0) {
          let data = res.data
          if (+data.status === 1) {
            this.fetchPcDataFn()
            this.fetchGameData()
            this.fetchDrawChanceList()
          } else if (+data.status === 0) {
            this.isEnd = true
            const endPicData = data.data.pc
            this.endPic = endPicData
          }
        }
      })
      // .catch((error) => {
      //   this.$message.error(error.errmsg);
      // });
    },
    fetchPcDataFn() {
      fetchPcData({ refresh: this.refresh }).then((res) => {
        const data = res.data.data
        if (+res.data.status === 0) {
          this.bannerData = data.pc.banner
          this.focusData = data.pc.focus
          this.prizeData = data.prize
          this.newsList = data.newsList || []
        } else {
          this.$message.error(res.errmsg)
        }
      })
      // .catch((error) => {
      //   this.$message.error(error.errmsg);
      // });
    },
    fetchGameData() {
      fetchGameData({ refresh: this.refresh }).then((res) => {
        const data = res.data.data
        let subCateList = []
        if (+res.data.status === 0) {
          this.gameData = data
          data.forEach((item) => {
            item.cateList.forEach((subItem, subIndex) => {
              if (subIndex === 0) {
                subCateList.push(subItem.subCateList)
              }
            })
          })
          this.subCateList = subCateList
        } else {
          // this.$message.error(res.errmsg);
        }
      })
      // .catch((error) => {
      //   this.$message.error(error.errmsg);
      // });
    },
    fetchDrawChanceList() {
      fetchDrawChanceList().then((res) => {
        if (+res.errcode === 0) {
          this.drawChanceList = res.data.list
          this.drawChanceNum = res.data.drawNum

          this.lotteryModalShow =
            Object.keys(this.drawChanceList).length > 0 ? true : false
        }
      })
      // .catch((error) => {
      //   this.$message.error(error.errmsg);
      // });
    },
    subCateClick(id, index) {
      subCateData({ subcateId: id, refresh: this.refresh }).then((res) => {
        this.subCateList.splice(index, 1, res.data.data)
      })
    },
    gameVote(docId, detailItem) {
      if (this.userInfo.uid) {
        this.gameId = docId
        gameVote({
          docId,
        }).then((res) => {
          //投票成功后voteNum++;
          if (+res.errcode === 0 || +res.errcode === 1) {
            const data = res.data
            this.voteType = data.type
            this.drawChanceNum = data.drawChanceNum
            this.successModalShow = true
            if (+data.type === 1) {
              detailItem.voteNum++
            }
            if (+data.type === 3) {
              this.voteTime = data.stime + '-' + data.etime
            }
          } else {
            this.$message.error(res.errmsg)
          }
        })
        // .catch((error) => {
        //   this.$message.error(error.errmsg);
        // });
      } else {
        this.$store.commit('account/setShowLoginMoadl', true)
      }
    },
    goLottery() {
      let lottery = this.$router.resolve({
        name: 'lottery',
        params: { id: this.gameId },
      })
      window.open(lottery.href, '_blank')

      // this.$router.push({ name: "lottery", params: { id: this.gameId } });
    },
    backTop() {
      window.scrollTo(0, 0)
    },
    showReviewLoop(index) {
      this.reviewSwiperOption.initialSlide = index
      this.isShowReviewLoop = true
    },
  },
}
</script>
<style lang="less" scoped>
@import '../assets/style/public.css';

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

body .show {
  display: block;
}

.home {
  background: #181623;
  color: #cac2b5;
  // padding-bottom: 100px;
}

.home-normal {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/kv.png')
      no-repeat center top,
    url('https://icon.zol-img.com.cn/2022active/1109jinling/images/loopBg.png')
      repeat-y center 1892px;
  padding-bottom: 100px;
}

.main-kv {
  height: 1010px;
}

.loop {
  height: 350px;
  overflow: hidden;
  margin: 0 auto 71px;
  position: relative;
}

/deep/ .loop .swiper-container-vertical > .swiper-pagination-bullets {
  right: 25px;
}

/deep/ .loop-bullet {
  width: 19px;
  height: 19px;
  background: url('../assets/images/normal_block.jpg') no-repeat;
  display: inline-block;
}

/deep/ .loop-bullet:focus {
  border: none;
  outline: none;
}

/deep/ .loop .loop-bullet {
  display: block;
  margin-bottom: 8px;
}

/deep/ .loop-bullet-active {
  background: url('../assets/images/act_block.png') no-repeat;
}

.news {
  margin-bottom: 112px;
}

.news-l {
  width: 700px;
  height: 250px;
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/bg1.jpg')
    no-repeat;
  position: relative;
}

.news-loop {
  width: 700px;
  height: 300px;
  position: absolute;
  left: 11px;
  top: 18px;
}

.news-loop .swiper-slide :hover p {
  font-weight: bold !important;
}

/deep/ .loop-bullet {
  margin: 0 4px;
}

/deep/ .swiper-pagination {
  text-align: right;
}

/deep/ .news .swiper-container-horizontal > .swiper-pagination-bullets {
  width: auto;
  right: 11px;
  left: auto;
}

/deep/ .news .swiper-slide p {
  height: 54px;
  line-height: 54px;
  background: #000;
  font-size: 16px;
  color: #cac2b5;
  text-indent: 1em;
  margin: 0;
  text-align: left;
  width: 100%;
  padding-right: 20%;
}

/deep/ .news .swiper-slide a:hover p {
  color: #fff;
}

.news-r {
  width: 483px;
  height: 297px;
  padding: 29px 32px 15px 22px;
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/newsBg.png')
    no-repeat right top;
}

.news-r li {
  line-height: 50px;
  height: 50px;
  padding-bottom: 1px;
  background: url('../assets/images/line1.png') no-repeat left bottom;
  cursor: pointer;
}

.news-r li:hover .news-date {
  color: #b07d3d;
}

.news-r li:hover .news-title {
  font-weight: bold;
  color: #fff;
}

.news-r li a {
  font-size: 16px;
}

.news-date {
  color: #715737;
  padding-right: 12px;
  float: left;
  font-family: 'arial narrow';
}

.news-title {
  color: #cac2b5;
  float: left;
  width: 300px;
  text-align: left;
}

.classify {
  margin-bottom: 104px;
}

.classify-item {
  margin-bottom: 114px;
}

.t1 {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/t1.png')
    no-repeat center top;
}

.classify-box {
  // background: url("../assets/images/classify-c.png") repeat-y center top;
  // border: 2px solid;
  // border-image: linear-gradient(153deg,
  //     rgba(209, 48, 52, 0.9),
  //     rgba(97, 89, 126, 0.9)) 2 2;
}

.classify-box-in {
  padding: 54px 52px 20px 0px;
  background: url(https://icon.zol-img.com.cn/2022active/1109jinling/images/bg_tou.png)
      no-repeat top,
    url(https://icon.zol-img.com.cn/2022active/1109jinling/images/bg_di.png)
      no-repeat bottom;
  position: relative;

  // background: linear-gradient(133deg, #131832, #281D2B);
  &::before {
    content: '';
    background: url(https://icon.zol-img.com.cn/2022active/1109jinling/images/bg_pingpu.png)
      repeat-y;
    position: absolute;
    width: 100%;
    left: 0;
    top: 180px;
    bottom: 180px;
    display: block;
  }
}

.classify-box-center {
  position: relative;
  z-index: 9;
}

.classify-tab-item {
  margin-left: 2px;
  width: 130px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 50px;
  // line-height: 50px;
  background: #602935;
}

.classify-tab {
  width: 158px;
  // height: 567px;
  overflow-x: hidden;
  overflow-y: auto;
}

.classify-tab span {
  width: 130px;
  display: block;
  line-height: 18px;
  padding: 9px 15px 0;
  margin-bottom: 9px;
  font-weight: bold;
  font-size: 14px;
  color: #cac2b5;
  box-sizing: inherit;
  text-align: left;
}

.classify-tab .classify-tab-item:hover,
.classify-tab .classify-tab-item.cur {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/gradient_btn.jpg')
    no-repeat;

  span {
    color: #f6e3cd;
  }
}

.classify-tab::-webkit-scrollbar {
  width: 8px;
}

.classify-tab::-webkit-scrollbar-thumb {
  background: #602935;
}

.classify-cont,
.classify-cont ul {
  width: 984px;
  // height: 544px;
  overflow: hidden;
}

.classify-cont ul {
  max-height: 580px;
}

.classify-cont ul.showAll {
  height: auto;
  max-height: initial;
}

.classify-cont,
.classify-cont ul.showAll {
  height: auto;
}

.classify-cont li {
  width: 226px;
  height: 252px;
  border: 1px solid #682836;
  padding: 9px 9px 14px 9px;
  float: left;
  margin: 0 0 39px 20px;

  a {
    color: #cac2b5;
  }
  img {
    background-color: #fff;
  }
}

.classify .more {
  width: 80px;
  background: url('../assets/images/more_normal.png') no-repeat right center;
  color: #cac2b5;
  text-align: left;
  line-height: 1;
  display: block;
  clear: both;
  margin: 0 auto 20px;
}

.classify .more:hover {
  color: #fff;
  background: url('../assets/images/more_act.png') no-repeat right center;
}

.game-name {
  font-size: 16px;
  margin: 16px auto 19px;
  display: block;
  line-height: 1;
  font-weight: bold;

  &:hover {
    color: #fff;
  }
}

.game-vote {
  font-size: 12px;
  color: #8f6c48;
  line-height: 26px;
}

.vote-num {
  font-size: 14px;
  color: #fff;
}

.vote-btn {
  width: 69px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  color: #f5e7d0;
  background: #895c3e;
  border-radius: 2px;
}

.vote-btn:hover {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/gradient_btn.jpg')
    no-repeat;
  color: #fff;
  font-weight: bold;
}

.award {
  height: 431px;
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/award_special_area.png')
    no-repeat center top;
  margin-bottom: 100px;
}

.award .common-title {
  height: 78px;
  line-height: 78px;
  margin-bottom: 70px;
}

.award-title {
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 48px;
  margin-bottom: 69px;
  box-sizing: inherit;
}

.award-loop {
  height: 226px;
  position: relative;
}

.award-loop .swiper-container {
  width: 1098px;
  margin: 0 auto;
}

.award-loop .swiper-slide {
  width: 210px !important;
  height: 157px !important;
  background: #ffffff;
}

.award-loop .swiper-slide img {
  width: 210px;
  height: 157px;
  background-color: #fff;
}

.award-loop .swiper-slide p {
  line-height: 1;
  margin-top: 30px;
  color: #b3aca3;
}

.award-loop .swiper-button-next,
.award-loop .swiper-button-prev {
  width: 63px;
  height: 94px;
  top: 34px;
  margin-top: 0;
}

.award-loop .swiper-button-next:hover {
  background: #000 url('../assets/images/arrow_r.png') no-repeat center center;
  opacity: 0.5;
}

.award-loop .swiper-button-prev:hover {
  background: #000 url('../assets/images/arrow_l.png') no-repeat center center;
  opacity: 0.5;
}

.award-loop .swiper-button-prev {
  background: url('../assets/images/arrow_l.png') no-repeat center center;
  left: 1px;
}

.award-loop .swiper-button-next {
  background: rgba(0, 0, 0, 0) url('../assets/images/arrow_r.png') no-repeat
    center center;
  right: 1px;
}

.t2 {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/t2.png')
    no-repeat center top;
}

.review {
  margin-bottom: 98px;
  position: relative;
}

.review img {
  display: block;
  margin-bottom: 9px;
}

.review-l {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/review.png')
    no-repeat right 467px;
}

.review-r {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/previous.png')
    no-repeat 306px top;
}

.review-cont-click img {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.review-cont-click .review-l img,
.review-cont-click .review-r img {
  background: #000;
}

.review-loop {
  position: absolute;
  left: 68px;
  top: 88px;
  width: 1063px;
}

.review-loop .swiper-container {
  margin: 0 auto;
  width: 1000px;
}

.review-next,
.review-prev {
  width: 22px;
  height: 30px;
  margin-top: -20px;
}

.review-next {
  background: url('../assets/images/arrow_r.png') no-repeat;
  right: -64px;
}

.review-prev {
  background: url('../assets/images/arrow_l.png') no-repeat;
  left: -64px;
}

.close-review-loop {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 10px;
  top: -36px;
  background: url('../assets/images/close-loop.png') no-repeat;
}

.close-review-loop:hover {
  background: url('../assets/images/close-loop-hover.png') no-repeat;
}

.t3 {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/t3.png')
    no-repeat center top;
}

.partners-cont {
  width: 1200px;
  height: 438px;
  padding: 52px 40px 0;
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/partners_border.png')
    no-repeat center top;
  background-size: 100% 438px;
}

.partners-cont img {
  margin: 0 10px 20px;
  float: left;
}

.partners-cont .swiper-pagination {
  text-align: center;
  bottom: 28px;
}

.back-top {
  width: 68px;
  height: 74px;
  background: url('../assets/images/backTop.png') no-repeat;
  position: fixed;
  left: 50%;
  margin-left: 626px;
  bottom: 350px;
}

.draw-chance-modal /deep/ .common-modal-box {
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/drawChanceBgt.png')
      no-repeat 100px -20px,
    url('https://icon.zol-img.com.cn/2022active/1109jinling/images/drawChanceBgb.png')
      no-repeat left bottom;
  background-color: #faf2e5;
  background-size: auto auto;
  height: 600px;
}

.draw-chance-tip {
  width: 436px;
  height: 46px;
  padding: 4px 5px;
  background: #e6c47b;
  margin: 0 auto 27px;
}

.draw-chance-tip-in {
  width: 426px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #faf2e5;
  display: flex;
  justify-content: center;
}

.game-chance-txt {
  font-size: 14px;
  font-weight: 400;
  color: #c69560;
}

.draw-chance-num {
  margin: 0 14px;
  font-size: 28px;
  color: #e84043;
  line-height: 38px;
}

.draw-classify-item {
  width: 436px;
  margin-left: 24px;

  li {
    line-height: 76px;
    height: 76px;
    border-bottom: 1px solid #f3e6d0;
  }
}

.classify-title {
  height: 40px;
  line-height: 40px;
  color: #c69560;
  background: #f3e6d0;
  padding: 0 22px;
}

.draw-game-name {
  color: #ad4d15;
  // height: 76px;
  line-height: 18px;
  margin: 0;
  // padding: 18px 0 18px 23px;
  padding: 26px 0 0px 23px;
  box-sizing: content-box;
  width: 186px;
  font-weight: bold;
}

.cur-chance-num {
  color: #e84043;
  margin: 20px 0 8px;
}

.toLottery {
  width: 78px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-top: 24px;
  color: #b94706;
}

.draw-classify-item li.no-border {
  border: none;
}

.draw-chance-cont {
  width: 482px;
  height: 318px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto 23px;
}

/*滚动条样式*/
.draw-chance-cont::-webkit-scrollbar {
  width: 10px;
}

.draw-chance-cont::-webkit-scrollbar-thumb {
  background: #e2d1b3;
}

/deep/ .banner .swiper-slide img {
  width: 100%;
  height: 100%;
}

/deep/ .logo .swiper-slide img {
  width: 120px;
  height: 60px;
}

.congratulations {
  margin: 10px 0 16px;
}

.toDraw {
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 40px auto 0;
}
.bigImg{
  padding-top: 90px;
}
</style>
