import "./style.css";
// Toast
var toastBox;
var toast = function (c, t, s, h) {
  toast.dismiss = function () {
    if (typeof h == "function") {
      h.call(toastBox);
    } else {
      toastBox.classList.remove("visible");
    }
    c.timeout = setTimeout(function () {
      toastBox.style.display = "none";
    }, 400);
    toast.dismiss = function () {};
    return toastBox;
  };
  if (typeof c === "string") {
    if (!toastBox) {
      toastBox = document.createElement("div");
      toastBox.className = "toast";
      document.body.appendChild(toastBox);
      toastBox.addEventListener("click", toast.dismiss);
      // toastBox.lastTop = toastBox.currentStyle['top'];
      toastBox.moveTo = toast.moveTo;
      toastBox.dismiss = toast.dismiss;
    }
    toastBox.innerHTML = c;
    c = toastBox;
  }
  // if (toastBox.lastTop) {
  // toastBox.style.top = toastBox.lastTop;
  // }
  if (typeof s == "function") {
    s.call(c);
  } else {
    c.classList.add("visible");
  }
  if (c.timeout) {
    clearTimeout(c.timeout);
  }
  toastBox.style.display = "block";
  if (t !== Infinity) {
    t = parseInt(t) || 0;
    if (t < 1) {
      t = 2000;
    }
    c.timeout = setTimeout(toast.dismiss, t);
  }
  return c;
};
toast.moveTo = function (target) {
  var y, bounds;
  if (
    toastBox &&
    target &&
    target.getBoundingClientRect &&
    (bounds = target.getBoundingClientRect())
  ) {
    y = bounds.top - toastBox.offsetHeight + 55;
    if (y < 0) {
      y = bounds.bottom + 10;
    }
    toastBox.style.top = y + "px";
  } else {
    toastBox.style.top =
      window.innerHeight - toastBox.getBoundingClientRect().height / 2 + "px";
  }

  return toastBox;
};

export { toast };
