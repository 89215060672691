import { request } from "@/utils/request";
// 获取首页结束大图
export const fetchEndPic = (data) => {
  return request({
    url: `/index.end.pic`,
    method: "get",
    data,
  });
};

// 获取banner图，轮播图，奖品专区
export const fetchPcData = (data) => {
  return request({
    url: `/index.img.list`,
    method: "get",
    data,
  });
};

// 获取游戏区块数据
export const fetchGameData = (data) => {
  return request({
    url: `/index.game.list`,
    method: "get",
    data,
  });
};

// 获取游戏区块右部数据
export const subCateData = (data) => {
  return request({
    url: `/index.game.sublist`,
    method: "get",
    data,
  });
};

// 获取导航列表
export const fetchNavList = (data) => {
  return request({
    url: `/index.nav.list`,
    method: "get",
    data,
  });
};

// 获取抽奖机会列表
export const fetchDrawChanceList = (data) => {
  return request({
    url: `/get.draw.remind`,
    method: "get",
    data,
  });
};
