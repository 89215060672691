<template>
  <div>
    <Header></Header>
    <div>
      <!-- <slot></slot> -->
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./HeaderView.vue";
import Footer from "./FooterView.vue";
export default {
  components: { Header, Footer },
};
</script>

<style></style>
