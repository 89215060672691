<template>
  <div>
    <div class="footer">
      <div class="wrap clearfix">
        <span class="fl s1">年度优秀游戏评选大赛-金翎奖</span>
        <span class="fl s2">沪ICP备16012922号-12</span>
        <span class="fl s3">联系邮箱：jlj@howellexpo.net</span>
        <img
          class="fr footer-logo"
          src="https://icon.zol-img.com.cn/2022active/1109jinling/images/footer_logo.png"
          width="262"
          height="63"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-view",
};
</script>

<style lang="less" scoped>
.s1 {
  margin-right: 20px;
}
.s2 {
  margin-right: 57px;
}
.footer {
  text-align: center;
  line-height: 69px;
  height: 69px;
  font-size: 16px;
  // color: #827d76;
  color: #cac2b5;
  background: #070612;
}
.footer-logo {
  margin-top: 5px;
}
</style>
