import { request } from "@/utils/request";
/**
 * 登录服务
 * @param phone 手机号
 * @param code 短信验证码
 * @param agree 是否同意协议
 * @returns {Promise<AxiosResponse<T>>}
 */
// export async function login(phone, code, agree) {
//   return request("", METHOD.POST, {
//     phone: phone,
//     code: code,
//     agree: agree,
//   });
// }

export function login(data) {
  return request({
    url: `/login`,
    method: "post",
    data,
  });
}

/**
 * 退出登录
 */
export function logout(data) {
  return request({
    url: `/logout`,
    method: "get",
    data,
  });
}

export function getUserInfo(data) {
  return request({
    url: `/get.user.info`,
    method: "get",
    data,
  });
}
export default {
  login,
  logout,
  getUserInfo,
};

/**
 * 动态密码
 */
export function fetchPassword(data) {
  return request({
    url: `/send.phone.code`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

/**
 * 修改邮箱
 */
export function changeEmail(data) {
  return request({
    url: `/set.user.email`,
    method: "post",
    data,
  });
}

// 保存个人资料
export function changeUserInfo(data) {
  return request({
    url: `/set.user.info`,
    method: "post",
    data,
  });
}
