<template>
  <div>
    <div class="header">
      <div class="wrap clearfix">
        <router-link to="/" class="logo fl">
          <img src="../assets/images/logo.png" width="31" height="51" alt="" />
        </router-link>
        <div class="nav-box">
          <template>
            <a-dropdown>
              <router-link
                class="ant-dropdown-link header-home"
                :class="{ active: showNav }"
                to="/"
                >首页</router-link
              >
            </a-dropdown>
          </template>
          <template v-if="showNav">
            <a-dropdown
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body
                }
              "
              v-for="(item, index) in navList"
              :key="item.cateId"
            >
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                {{ item.cateName }}
              </a>
              <a-menu slot="overlay">
                <a-menu-item
                  v-for="subItem in item.cateList"
                  :key="subItem.subCateId"
                  @click="goAnchor(`${item.cateId}`, index, subItem.subCateId)"
                  >{{ subItem.subCateName }}</a-menu-item
                >
              </a-menu>
            </a-dropdown>
          </template>
        </div>
        <span href="#" class="fr login" v-if="userInfo && !userInfo.uid">
          <img
            src="https://icon.zol-img.com.cn/2022active/1109jinling/images/head.png"
            width="35"
            height="35"
            alt=""
          />
          <span class="login-txt" @click="login">登录</span>
        </span>
        <a-dropdown
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body
            }
          "
          class="fr login"
          v-else
        >
          <router-link
            class="ant-dropdown-link"
            target="_blank"
            :to="{ name: 'material' }"
          >
            <div class="header-avatar fl">
              <img
                :src="
                  loginPhoto && loginPhoto.length > 0
                    ? loginPhoto
                    : 'https://icon.zol-img.com.cn/2022active/1109jinling/images/head.png'
                "
                width="35"
                height="35"
                alt=""
              />
            </div>
            <!-- <span class="fl header-nickName single-row-ellipsis" v-if="userInfo && userInfo.nickName">{{
                userInfo.nickName
            }}</span> -->
            <!-- <a-icon type="down" /> -->
          </router-link>
          <a-menu slot="overlay" @click="exit">
            <a-menu-item class="exit" key="1">退出</a-menu-item>
          </a-menu>
        </a-dropdown>
        <LoginView></LoginView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getUserInfo, logout } from '@/services/user'
import { fetchNavList } from '@/services/home'
import LoginView from '@/components/LoginModal'
export default {
  name: 'header-view',
  computed: {
    ...mapState('account', ['showLoginMoadl', 'userInfo']),
  },
  data() {
    return {
      showNav: false,
      navList: [],
      loginPhoto: '',
      refresh: '',
    }
  },
  components: { LoginView },
  watch: {
    $route: {
      handler() {
        if (this.$route.path === '/') {
          this.showNav = true
        } else {
          this.showNav = false
        }
      },
      immediate: true,
    },
    userInfo: {
      handler(newVal) {
        this.loginPhoto = newVal.headPic ? newVal.headPic : newVal.headPicUrl
      },
      deep: true,
    },
  },
  created() {
    this.refresh = this.$route.query.refresh
    this.init()
    this.getNavList()
  },
  methods: {
    ...mapMutations('account', ['setShowLoginMoadl']),
    init() {
      getUserInfo().then((data) => {
        const res = data.data
        this.$store.commit('account/setUserInfo', res)

        if (res && res.headPicUrl) {
          this.loginPhoto = res.headPicUrl
        }
      })
    },
    onClick() {
    },

    login() {
      this.$store.commit('account/setShowLoginMoadl', true)
    },
    exit() {
      this.$store.commit('account/removeUserInfo')
      logout().then(() => {
        this.$router.push('/')
        window.location.reload()
      })
    },
    getNavList() {
      fetchNavList({refresh:this.refresh}).then((res) => {
        if (+res.errcode === 0) {
          this.navList = res.data.data
        }
      })
      // .catch((error) => {
      //   this.$message.error(error);
      // });
    },
    goAnchor(id, index, subCateId) {
      const obj = {
        id: subCateId,
        index,
      }
      this.$store.commit('account/setNavObj', JSON.parse(JSON.stringify(obj)))
      document.querySelector(`#anchor${id}`)&&document.querySelector(`#anchor${id}`).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.fl {
  float: left;
}

.fr {
  float: right;
}

.wrap {
  width: 1200px;
  margin: 0 auto;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: #13151c;
  border-bottom: 1px solid #0a090b;
}

.logo {
  margin: 24px 62px 13px 36px;
}

.login {
  margin: 29px 0 0 24px;
  color: #cac2b5;
}

.login-txt:hover {
  color: #faf2e5;
}

.login-txt {
  cursor: pointer;
  margin-left: 18px;
}

.login img {
  // margin-right: 18px;
}

.nav-box {
  height: 90px;
  float: left;
}

.header-home {
  width: 106px;
}

/deep/ .nav-box .ant-dropdown-link {
  float: left;
  color: #f0dec2;
  max-width: 126px;
  height: 90px;
  line-height: 90px;
  font-size: 16px;
  // padding: 0 30px;
  padding: 0 15px;
  // display: inline-block;
  overflow: hidden;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-all;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
}

/deep/ .nav-box .ant-dropdown-link:hover {
  color: #e4b653;
  background: url('https://icon.zol-img.com.cn/2022active/1109jinling/images/nav_act.png')
    no-repeat center bottom;
  background-size: 100% 100%;
}

:global(.ant-dropdown) {
  top: 92px !important;
}

// /deep/ .ant-dropdown-menu {
.nav-box :global(.ant-dropdown-menu) {
  // width: 210px;
  // height: 458px;
  background: #202432;
  border: 1px solid;
  border-image: linear-gradient(
      135deg,
      rgba(255, 202, 44, 0.23),
      rgba(249, 201, 57, 0.23)
    )
    1 1;
  background: #202432 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

/deep/ .ant-dropdown-menu:hover {
  color: #e4b653;
}

.nav-box :global(.ant-dropdown-menu-item) {
  font-size: 16px;
  color: #cac2b5;
  line-height: 48px !important;
  height: 48px;
  padding: 0 24px !important;
  background: url('../assets/images/separator.png') no-repeat center bottom;
}

/deep/ .ant-dropdown-menu-item,
/deep/ .ant-dropdown-menu-submenu-title {
  color: #cac2b5;
}

/deep/ .ant-dropdown-menu-item:hover,
/deep/ .ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
  color: #e4b653;
}

.exit {
  color: #e4b653;
}

.header-avatar {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #13151c;
  float: left;

  img {
    // transform: scale(1.5);
    border-radius: 50%;
  }
}

.header-home.active {
  color: #e4b653;
  background: url(https://icon.zol-img.com.cn/2022active/1109jinling/images/nav_act.png)
    no-repeat center bottom;
  background-size: 100% 100%;
}

.header-nickName {
  margin: 9px 0 0 17px;
  max-width: 62px;
}
</style>
