<template>
  <div id="login-page" v-if="showLoginMoadl">
    <div class="login-mask"></div>
    <div class="login-box gradient-border">
      <div class="message-login" v-if="!isToverifyEmail">
        <div class="login-title f16">短信登录</div>
        <div class="phone-box f14 clearfix">
          <span class="phone-icon fl"></span>
          <input
            class="fl"
            type="number"
            placeholder="请输入手机号"
            v-model.trim="phoneNum"
            @keyup="limitLength(phoneNum, 11, 'phoneNum')"
          />
        </div>
        <p
          class="phone-tip tip-box"
          :class="{
            error: phoneNumTip.length > 0,
          }"
        >
          {{ phoneNumTip }}
        </p>
        <div class="verify-code-box clearfix">
          <input
            class="verify-code-ipt mr fl"
            type="text"
            placeholder="请输入验证码"
            v-model.trim="verifyCode"
            @keyup="limitLength(verifyCode, 4, 'verifyCode')"
          />
          <img
            :src="graphicVerificationCode"
            id="codeImg"
            class="fl"
            width="110"
            height="46"
          />
          <span
            id="refresh"
            class="fl pointer f14 clearfix"
            @click="getGraphicVerificationCode"
            ><em class="changeIcon fl"></em
            ><i class="changeTxt fl">换一换</i></span
          >
        </div>
        <p
          class="verify-code-tip tip-box"
          :class="{ error: verifyCodeTip.length > 0 }"
        >
          {{ verifyCodeTip }}
        </p>
        <div class="password-box clearfix">
          <input
            class="password-ipt mr fl"
            type="number"
            placeholder="请输入动态密码"
            v-model.trim="password"
            @keyup="limitLength(password, 6, 'password')"
          />
          <span class="getPasswordBtn pointer f12 fl" @click="getPassword">{{
            +countDown <= 120 && +countDown !== 0
              ? `动态密码已发送(${countDown}s)`
              : "获取动态密码"
          }}</span>
        </div>
        <p
          class="password-tip tip-box"
          :class="{ error: passwordTip.length > 0 }"
        >
          {{ passwordTip }}
        </p>
        <div
          :class="[
            'nextStepBtn',
            'f16',
            'mb',
            'pointer',
            hasAgree ? 'common-btn-halftone' : 'common-btn-light',
          ]"
          @click="nextStepFn"
        >
          下一步
        </div>
        <div class="agreement-tip f14">
          <span
            class="state-txt pointer"
            :class="{ act: hasAgree }"
            @click="agreeHandler"
            >我已阅读并同意<router-link
              target="_blank"
              class="agreement"
              to="/agreement"
              >《【金翎奖】隐私政策》</router-link
            >协议</span
          >
        </div>
      </div>
      <div class="email-verify" v-else>
        <div class="login-title f16 email-title">邮箱验证</div>
        <div class="email-box clearfix">
          <span class="email-icon fl"></span>
          <input
            class="fl"
            type="text"
            placeholder="请输入接收中奖信息的邮箱地址"
            v-model.trim="email"
            @keyup="limitLength(email, 30, 'email')"
          />
        </div>
        <p class="email-tip tip-box">
          注： 邮箱是为了能及时通知您中奖相关信息，请认真填写。
        </p>
        <p class="email-tip tip-box">{{ emailTip }}</p>
        <div
          :class="[
            'sureBtn',
            'pointer',
            email.length > 0 ? 'common-btn-halftone' : 'common-btn-light',
          ]"
          @click="verifyEmail"
        >
          确定
        </div>
      </div>
      <span class="close-login" @click="closeLogin"></span>
    </div>
    <div class="agreement-box">
      <h4>《金翎奖用户协议》</h4>
      <div class="agreement-cont"></div>
    </div>
  </div>
</template>
<script>
import { fetchPassword, login, changeEmail } from "@/services/user";
import { mapMutations, mapState } from "vuex";
import { toast } from "@/components/toast/script";

export default {
  name: "LoginModal",
  data: function () {
    return {
      isToverifyEmail: false,
      hasAgree: true,
      phoneNum: "",
      verifyCode: "",
      password: "",
      email: "",
      graphicVerificationCode: "",
      countDown: 121,
      canClick: true,
      phoneNumTip: "",
      verifyCodeTip: "",
      passwordTip: "",
      emailTip: "",
      userInfo: [],
    };
  },
  computed: {
    ...mapState("account", ["showLoginMoadl"]),
  },
  // created() {
  // },
  mounted() {
    this.getGraphicVerificationCode();
  },
  methods: {
    ...mapMutations("account", ["setShowLoginMoadl"]),
    closeLogin() {
      this.$store.commit("account/setShowLoginMoadl", false);
      this.isToverifyEmail = false;
      this.phoneNumTip = "";
      this.verifyCodeTip = "";
      this.passwordTip = "";
      this.emailTip = "";
    },
    agreeHandler(e) {
      if (Array.from(e.target.classList).indexOf("agreement") == -1) {
        this.hasAgree = !this.hasAgree;
      }
    },
    getGraphicVerificationCode() {
      this.graphicVerificationCode = `https://jlj.chinajoy.net/api/graphic.verification.code?width=110&height=42&c=${Math.random()}`;
    },
    getPassword() {
      if (!this.canClick) return;
      this.canClick = false;
      this.countDown = 121;
      let passwordCheckSuccess = true;
      const { phoneNum, verifyCode } = this;

      if (!phoneNum.length) {
        this.phoneNumTip = "请输入您的手机号~";
        passwordCheckSuccess = false;
      }

      if (!/^1[3456789]\d{9}$/.test(phoneNum)) {
        this.phoneNumTip = "请输入正确的手机号~";
        passwordCheckSuccess = false;
      }

      if (!verifyCode.length) {
        this.verifyCodeTip = "请输入验证码~";
        passwordCheckSuccess = false;
      }
      if (!this.hasAgree) {
        toast("请先勾选同意协议~");
        passwordCheckSuccess = false;
      }
      if (passwordCheckSuccess) {
        fetchPassword({
          phone: phoneNum,
          code: verifyCode,
          from: 1,
        })
          .then((res) => {
            if (+res.errcode === 0) {
              if (res.data.countdown) {
                this.countDown = res.data.countdown;
              }
              this.timeDown();
            } else {
              this.verifyCodeTip = res.errmsg;
              this.canClick = true;
            }
          })
          .catch(() => {
            this.canClick = true;
          });
      } else {
        this.canClick = true;
      }
    },
    timeDown() {
      let timer = null;
      this.countDown--;
      timer = setInterval(() => {
        this.countDown--;
        if (+this.countDown <= 0) {
          clearInterval(timer);
          this.canClick = true;
        }
      }, 1000);
    },
    nextStepFn() {
      let checkSuccess = 1;
      let phone = this.phoneNum;
      let verifyCode = this.verifyCode;
      let password = this.password;
      // 表单校验
      if (!phone.length) {
        this.phoneNumTip = "请输入您的手机号~";
        checkSuccess = false;
      }

      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.phoneNumTip = "请输入正确的手机号~";
        checkSuccess = false;
      }

      if (!verifyCode.length) {
        this.verifyCodeTip = "请输入验证码~";
        checkSuccess = false;
      }

      if (!password.length) {
        this.passwordTip = "请输入动态密码~";
        checkSuccess = false;
      }

      if (checkSuccess) {
        login({
          phone: phone,
          code: password,
          agree: this.hasAgree ? 1 : false,
        }).then((res) => {
          const data = res.data;
          // 根据后台返回结果看是直接登录还是进行邮箱验证,如果需要验证邮箱，则this.isToverifyEmail=true;
          if (+res.errcode === 0 || +res.errcode === 1) {
            if (+data.haveEmail === 0) {
              this.userInfo = data;
              this.isToverifyEmail = true;
            } else {
              this.$store.commit("account/setUserInfo", data);
              this.closeLogin();
            }
          } else {
            toast(res.errmsg);
          }
        });
        // .catch((error) => {
        //   console.log(error);
        //   this.passwordTip = "请输入正确的动态密码";
        // });
      }
    },
    verifyEmail() {
      let mailCheckSuccess = true;
      let reg =
        /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!this.email.length) {
        this.emailTip = "请输入接收中奖信息的邮箱地址~";
        mailCheckSuccess = false;
      }

      if (!reg.test(this.email)) {
        this.emailTip = "请输入正确的接收中奖信息的邮箱地址~";
        mailCheckSuccess = false;
      }

      if (mailCheckSuccess) {
        this.changeEmailFn();
      }
    },
    changeEmailFn() {
      changeEmail({
        email: this.email,
      }).then((res) => {
        // 如果提交成功则弹窗隐藏
        this.$store.commit("account/setUserInfo", this.userInfo);
        this.closeLogin();
        this.isToverifyEmail = false;
      });
      // .catch((error) => {
      //   console.log(error, "xxxxxx");
      // });
    },
    limitLength(val, num, str) {
      this[str] = val.length > num ? val.slice(0, num) : val;
      let saveTip = this[str + "Tip"];
      if (val.length > 0) {
        this[str + "Tip"] = "";
      } else {
        this[str + "Tip"] = saveTip;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/style/public.css";

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}

#login-page {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  input {
    border: 1px solid #f3e6d0;
    background: #f3e6d0;
    padding: 10px;
    color: #ad4d15;
  }

  input:focus {
    color: #ad4d15;
    outline: none;
    //   outline: 1px solid #c69560;
  }

  input.error {
    outline: 1px solid #e84043;
  }

  .tip-box {
    line-height: 20px;
    height: 20px;
    font-size: 12px;
    color: #e84043;
    margin: 0;
  }

  .login-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.5;
  }

  .login-box {
    width: 486px;
    height: 436px;
    background: #faf2e5;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -240px;
    margin-top: -218px;
    text-align: left;
    padding: 60px 58px;
  }

  .login-title {
    color: #ad4d15;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .phone-box,
  .email-box {
    height: 42px;
    line-height: 1;
    position: relative;
    background: #f3e6d0;

    input {
      height: 42px;
      line-height: 22px;
      padding: 10px 10px 10px 42px;
      width: 100%;
    }

    .phone-icon,
    .email-icon {
      position: absolute;
      width: 14px;
      height: 14px;
      left: 10px;
      top: 14px;
    }

    .phone-icon {
      background: url("../assets/images/phone_icon.png") no-repeat left center;
    }

    .email-icon {
      background: url("../assets/images/email_icon.png") no-repeat left center;
    }

    &::before {
      position: absolute;
      width: 1px;
      height: 14px;
      background: #e6c47b;
      left: 32px;
      top: 14px;
    }
  }

  .email-box input {
    margin-bottom: 5px;
  }

  .verify-code-ipt,
  .password-ipt {
    width: 160px;
  }

  .getPasswordBtn {
    display: inline-block;
    width: 190px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #eacd8f;
    border-radius: 3px;
    color: #ad4d15;
  }

  .getPasswordBtn:hover {
    background: #e6c47b;
  }

  #refresh {
    color: #c69560;
    margin: 14px 0 0 20px;
  }

  #refresh:hover {
    text-decoration: underline;
  }

  .changeIcon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("../assets/images/refresh.png") no-repeat left top;
    line-height: 1;
    margin-top: 1px;
  }

  .changeTxt {
    display: inline-block;
    margin-left: 4px;
    line-height: 1;
  }

  .changeTxt:hover {
    color: #ad4d15;

    .changeIcon {
      background: url("../assets/images/refresh_hover.png") no-repeat left top;
    }
  }

  .nextStepBtn,
  .sureBtn {
    width: 360px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 3px;
    color: #d5916a;
  }

  .agreement {
    color: #c69560;
  }

  .agreement-tip {
    color: #c69560;
    text-align: center;
  }

  .state-txt {
    display: inline-block;
    padding-left: 20px;
    line-height: 20px;
    background: url("../assets/images/icon_unselect.png") no-repeat left 3px;
  }

  .state-txt.act {
    background: url("../assets/images/icon_select.png") no-repeat left 3px;
  }

  .close-login {
    position: absolute;
    width: 27px;
    height: 27px;
    right: 15px;
    top: 15px;
    background: url("../assets/images/close_login.png") no-repeat;
  }

  .email-title {
    margin-top: 63px;
  }

  .email-tip {
    margin-bottom: 8px;
  }

  .agreement-box {
    width: 1200px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #faf2e5;
    padding: 72px 100px 66px;
    left: 50%;
    margin-left: -600px;
    position: absolute;
    top: 128px;
    z-index: 999;
    display: none;
  }
}
</style>
