// 头部轮播图
export const loopSwiperOption = {
  direction: "vertical",
  height: 350,
  speed: 1500,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
  },
  loopAdditionalSlides: 2,
  pagination: {
    el: ".loop .swiper-pagination", //分页器
    clickable: true,
    bulletClass: "loop-bullet",
    bulletActiveClass: "loop-bullet-active",
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  },
};

// 新闻列表轮播图
export const newsSwiperOption = {
  direction: "horizontal",
  height: 300,
  speed: 1500,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
  },
  loopAdditionalSlides: 2,
  pagination: {
    el: ".news .swiper-pagination", //分页器
    clickable: true,
    bulletClass: "loop-bullet",
    bulletActiveClass: "loop-bullet-active",
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  },
};

// 奖品专区轮播图
export const awardSwiperOption = {
  direction: "horizontal",
  height: 300,
  speed: 1500,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
  },
  slidesPerView: 5,
  spaceBetween: 12,
  slidesPerGroup: 5,
  // loopAdditionalSlides: 2,
  navigation: {
    nextEl: ".award .swiper-button-next",
    prevEl: ".award .swiper-button-prev",
  },
};

// 往届回顾弹窗轮播图
export const reviewSwiperOption = {
  direction: "horizontal",
  height: 750,
  speed: 1500,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
  },
  initialSlide: 0,
  loopAdditionalSlides: 2,
  centeredSlides: true,
  navigation: {
    nextEl: ".review .swiper-button-next",
    prevEl: ".review .swiper-button-prev",
  },
};

// 合作伙伴轮播图
export const partnersSwiperOption = {
  direction: "horizontal",
  height: 350,
  speed: 1500,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
  },
  loopAdditionalSlides: 2,
  pagination: {
    el: ".partners-cont .swiper-pagination", //分页器
    clickable: true,
    bulletClass: "loop-bullet",
    bulletActiveClass: "loop-bullet-active",
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  },
};

// 抽奖结果列表
export const lotterySwiperOption = {
  direction: "vertical",
  height: 290,
  speed: 1000,
  slidesPerView: 5,
  slidesPerGroup: 1,
  loop:true,
  autoplay: {
    disableOnInteraction: false,
    delay: 0,
  },
  // loopAdditionalSlides: 2,
};

// 往届回顾图片
export const reviewPicArr = [
  {
    img: "https://icon.zol-img.com.cn/2022active/1109jinling/images/review1.jpg",
    ulr: "",
  },
  {
    img: "https://icon.zol-img.com.cn/2022active/1109jinling/images/review2.jpg",
    ulr: "",
  },
  {
    img: "https://icon.zol-img.com.cn/2022active/1109jinling/images/review3.jpg",
    ulr: "",
  },
  {
    img: "https://icon.zol-img.com.cn/2022active/1109jinling/images/review4.jpg",
    ulr: "",
  },
];

export const partnersArr = [
  [
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic01.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic02.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic03.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic04.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic05.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic06.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic07.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic08.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic09.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic10.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic11.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic12.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic13.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic14.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic15.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic16.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic17.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic18.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic19.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic20.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic21.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic22.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic23.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic24.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic25.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic26.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic27.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic28.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic29.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic30.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic31.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic32.jpg",
  ],
  [
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic33.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic34.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic35.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic36.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic37.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic38.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic39.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic40.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic41.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic42.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic43.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic44.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic45.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic46.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic47.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic48.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic49.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic50.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic51.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic52.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic53.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic54.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic55.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic56.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic57.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic58.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic59.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic60.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic61.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic62.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic63.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic64.jpg",
  ],
  [
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic65.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic66.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic67.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic68.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic69.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic70.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic71.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic72.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic73.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic74.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic75.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic76.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic77.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic78.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic79.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic80.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic81.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic82.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic83.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic84.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic85.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic86.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic87.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic88.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic89.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic90.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic91.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic92.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic93.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic94.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic95.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic96.jpg",
  ],
  [
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic97.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic98.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic99.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic100.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic101.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic102.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic103.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic104.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic105.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic106.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic107.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic108.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic109.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic110.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic111.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic112.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic113.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic114.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic115.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic116.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic117.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic118.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic119.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic120.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic121.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic122.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic123.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic124.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic125.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic126.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic127.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic128.jpg",
  ],
  [
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic129.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic130.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic131.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic132.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic133.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic134.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic135.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic136.jpg",
    "https://icon.zol-img.com.cn/2022active/1109jinling/logo/pic137.jpg",
  ],
];
